@import './variable.scss';
@import './mixin.scss';
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Noto+Sans+JP:wght@300;400;500;700&display= swap');

body {
  margin: 0;
  // font-family: 'Source Sans Pro', sans-serif !important;
  // font-family: 'Noto Sans JP', sans-serif !important;
  font-family: 'Lato', 'Noto Sans JP', 'Hiragino Kaku Gothic ProN' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Common Ant Component CSS Start */
.amount-data-content {
  font-family: 'Lato', sans-serif !important;
}

.global-loader {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  z-index: 8 !important;
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.ant-form-item-has-error {
  .limit-form-input {
    border-color: $input-validation-border-color !important;
  }
}
.ant-layout {
  background-color: $app-bg-color !important;
  .ant-layout-content {
    padding: 24px 35px !important;
    z-index: 0;
  }
}
.ant-checkbox {
  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border: 2px solid $primary-background-color !important;
  }
}
.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: $primary-background-color !important;
  }
}
.ant-radio {
  .ant-radio-inner {
    width: 18px;
    height: 18px;
    border: 2px solid $primary-background-color !important;
  }
}
.ant-radio-checked {
  .ant-radio-inner {
    &:after {
      background-color: $primary-background-color;
    }
  }
}

.ant-pagination.custom-pagination {
  text-align: center;
  margin: 50px 0 20px;
  li.ant-pagination-disabled {
    button {
      color: rgba($black-color, 0.45);
    }
  }
  li.ant-pagination-prev,
  li.ant-pagination-next {
    button {
      @include commonCustomPagination;
    }
  }
  li.ant-pagination-item {
    @include commonCustomPagination;
  }
  li.ant-pagination-item-active {
    border-radius: 30px;
    background: #67b4a6;
    border-color: #67b4a6;
    a {
      color: $white-bg-1;
    }
  }
}
/* END */

/* Common Header Design Start */
.page-header-wrapper {
  z-index: 1;
  height: 52px !important;
  width: calc(100% - 93px);
  position: fixed;
  right: 0;
  background: $white-bg-1 !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.18);
  padding: 0 !important;
  line-height: 52px !important;
  .page-header-content-wrapper {
    display: flex;
    .logo-title-wrapper {
      cursor: pointer;
      margin-left: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: fit-content;
      .logo-icon {
        width: 35px;
        margin: 0 5px 10px 0;
        height: 35px;
      }
      .title {
        font-size: 20px;
        font-weight: 700;
        color: $black-color;
        text-align: left;
      }
    }
    .right-menu-wrapper {
      display: flex;
      height: 52px;
      justify-content: flex-end;
      padding-right: 20px;
      align-items: center;
      .help-menu-icon {
        margin: 5px 10px 0 0;
        cursor: pointer;
        svg {
          font-size: 20px;
          color: $help-icon-color;
        }
      }
      .lang-select {
        margin-right: 20px;
      }
      .username-title {
        background-color: $white-bg-1;
        font-size: 16px;
        font-weight: 700;
        padding: 0 20px;
        margin: 0;
      }
      .drawer-icon {
        cursor: pointer;
        margin-left: 20px;
      }
    }
  }
}
.without-sidebar {
  width: 100%;
}
/* END */

/* Common Sidebar Design Start */
.sider-layout-wrapper {
  .ant-layout-sider {
    min-height: 100vh;
    top: 0px !important;
    padding-top: 30px;
    background: $white-bg-1 !important;
    z-index: 1;
    width: 92px !important;
    min-width: 92px !important;
    flex: 0 0 92px !important;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.18);
  }
  .sider-content-wrapper {
    .ant-layout-sider-children {
      position: fixed;
      .ant-menu-root {
        border: 0;
        background-color: $white-bg-1;
        margin-top: 30px;
        .ant-menu-item {
          display: flex;
          height: 100%;
          width: 92px;
          flex-direction: column;
          justify-content: center;
          padding: 0 !important;
          margin: 25px 0;
          &:hover {
            svg {
              path[fill] {
                fill: rgba($active-navigation-tab-color, 1);
              }
              path[stroke] {
                stroke: rgba($active-navigation-tab-color, 1);
              }
            }
            span {
              color: rgba($active-navigation-tab-color, 1);
            }
          }
          &::after {
            border: 0;
          }
          svg {
            height: 30px;
            width: 30px;
          }
          span {
            font-size: 12px;
            font-weight: 700;
            margin: 4px 0 0;
            height: auto;
            line-height: normal;
            color: #7c7c7c;
            flex: inherit;
            white-space: break-spaces;
            text-align: center;
          }
        }

        // Design For Selected Item
        .ant-menu-item-selected {
          display: flex;
          justify-content: center;
          background: $white-bg-1;
          border-right: 4px solid rgba($active-navigation-tab-color, 1);
          svg {
            path[fill] {
              fill: rgba($active-navigation-tab-color, 1);
            }
            path[stroke] {
              stroke: rgba($active-navigation-tab-color, 1);
            }
          }
          span {
            color: rgba($active-navigation-tab-color, 1);
          }
        }
      }
    }
  }
}
/* END */

/* Sider Popover Design Start */
.account-popover {
  z-index: 0 !important;
  padding-top: 5px !important;
  .ant-popover-arrow {
    right: 10px !important;
  }
  .ant-popover-content {
    right: 5px !important;

    .ant-popover-arrow {
      span::before {
        background-color: $primary-background-color;
      }
    }

    .ant-popover-title {
      background-color: $primary-background-color;
      color: $white-bg-1;
      padding: 10px 15px;
      border-radius: 8px 8px 0 0;
    }

    .ant-popover-inner {
      border-radius: 8px;
    }

    .logout-content-wrapper {
      display: flex;
      cursor: pointer;
      .logout-icon {
        svg {
          margin-right: 10px;
          width: 20px;
          height: 20px;
        }
      }
      .logout-text {
        margin-top: -2px;
      }
    }
  }
}
.drawer-icon {
  width: 100%;
}
/* END */

/* Authentication Design Start */
.login-wrapper {
  background-color: $app-bg-color;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-form-container {
    width: 50%;
    border-radius: 8px;

    .ant-card-head-title {
      font-size: 20px;
    }
  }
  .login-footer-btn-wrapper {
    button {
      width: 120px;
      padding: 0 15px;
      font-size: 16px;
      background-color: $auth-button-color;
      border-color: $auth-button-color;
    }
    .ant-typography {
      font-size: 16px;
      color: $auth-button-color;
      cursor: pointer;
    }
  }
}
/* END */

/* Authentication Password Check Tooltip Design Start */
.password-check-tooltip {
  max-width: 500px !important;
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      span {
        background-color: $white-bg-1;
      }
      span:before {
        content: none;
      }
    }
    .ant-tooltip-inner {
      background-color: $white-bg-1;
      border-radius: 8px;
      color: $font-color;
      font-size: 14px;
      svg {
        font-size: 20px;
        margin-right: 5px;
      }
      .valid-icon {
        svg {
          color: $valid-password-color;
        }
      }
      li {
        display: flex;
        align-items: center;
        span:first-child {
          flex: 0;
        }
        .valid {
          span {
            color: $valid-password-color;
          }
        }
      }
    }
  }
}
/* END */

/* Project LookUp Page Design Start */
.project-lookup-wrapper {
  .white-bg {
    background-color: $white-bg-1;
  }
  .filter-card-wrapper {
    margin-top: 20px;
    .kpi-filter-criteria-wrapper {
      margin-top: 10px;
      .tag-details {
        display: flex;
        align-items: center;
        > span {
          margin-left: 5px;
        }
        .anticon-arrow-right {
          margin: 0 5px;
        }
      }
    }
    .tag-details {
      padding: 5px 10px;
      font-size: 14px;
      font-weight: 600;
      border-radius: 13px;
      .ant-tag-close-icon {
        margin-left: 12px;
        background-color: $table-border;
        border-radius: 50%;
        padding: 3px;
        svg {
          fill: $black-color;
        }
      }
    }
    .ant-row {
      .clear-icon {
        cursor: pointer;
        display: flex;
        align-items: center;
        background: $card-header-bg;
        padding: 5px 10px;
        border-radius: 50%;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
  .datatable-wrapper {
    .filter-card-wrapper {
      .filter-tabs {
        .ant-tabs-nav {
          .ant-tabs-nav-wrap {
            margin-bottom: 20px;
            .ant-tabs-nav-list {
              background: $project-tab-linear-gradient;
              .ant-tabs-tab {
                border: 0px;
                padding: 5px 45px 5px 35px;
                font-size: 16px;
                font-weight: 700;
                background-color: transparent;
                &:before {
                  content: '';
                  position: absolute;
                  width: 25px;
                  height: 25px;
                  border-top: 3px solid $white-bg-1;
                  border-right: 3px solid $white-bg-1;
                  transform: rotate(45deg);
                  right: 0px;
                }
                &:nth-last-child(2) {
                  &:before {
                    display: none;
                  }
                }
              }
              .ant-tabs-tab-active {
                &:after {
                  content: none;
                }
                .ant-tabs-tab-btn {
                  color: $active-tab-color;
                  position: relative;
                  padding-left: 20px;
                  &:before {
                    content: '';
                    position: absolute;
                    top: 7px;
                    left: 0px;
                    width: 11px;
                    height: 11px;
                    border-radius: 50%;
                    background-color: $active-tab-color;
                  }
                }
              }
            }
          }
        }
        .ant-tabs-content-holder {
          border-radius: 20px;
          border: 0px;
          padding: 25px 32px;
        }
      }
    }

    .ant-table-wrapper {
      @include commonTableWithGraph;
    }
  }
  .heading-row {
    margin-top: 10px;
    margin-bottom: 20px;
    .search-result-heading-wrapper {
      display: flex;
      align-items: center;
      .search-result-title {
        font-size: 22px;
        font-weight: 700;
        padding-left: 18px;
        border-left: 3px solid $primary-background-color;
      }
      .project-count {
        margin-left: 5px;
        font-size: 22px;
        font-weight: 700;
      }
    }
    .model {
      display: flex;
      justify-content: flex-end;
      .sort-option-select {
        margin-right: 10px;
        width: 150px;
        .ant-select-selector {
          border-radius: 4px;
          border: 1px solid $primary-background-color;
          color: $primary-background-color;
          .ant-select-selection-item {
            font-weight: 500;
          }
        }
        .ant-select-arrow {
          color: $primary-background-color;
        }
      }
      .model-btn {
        &:first-child {
          margin-right: 10px;
        }
        @include borderedButton;
      }
      .disabled-btn {
        color: rgba(22, 130, 139, 0.5);
        border-color: rgba(22, 130, 139, 0.5);
      }
    }
  }

  .model-btn {
    @include borderedButton;
  }
  .model-btn1 {
    color: $white-bg-1;
    border-color: $primary-background-color;
    background-color: $primary-background-color;
    border-radius: 5px;
  }
}
/* END */

/* Filter Card Design Start */
.filter-card-wrapper {
  .project-filter-accordion-wrapper {
    border-radius: 8px;
    border: 1px solid $primary-background-color;
    .ant-collapse-item {
      border-radius: 8px !important;
      background-color: $white-bg-1;
      .ant-collapse-header {
        font-size: 16px;
        font-weight: 600;
        padding: 12px 16px;
        .filter-accordion-title {
          margin-bottom: 10px;
          display: block;
        }
        .ant-collapse-expand-icon {
          > svg {
            right: 20px;
            top: 25px;
            transition: all 0.2s ease-in-out;
          }
          > .down-arrow-rotate {
            transition: all 0.2s ease-in-out;
            transform: translateY(-50%) rotate(180deg) !important;
          }
        }
        .ant-collapse-extra {
          color: $primary-background-color;
        }
      }
      .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 4px 25px 16px;
          .search-form-wrapper {
            .criteria-body-wrapper {
              background-color: $table-border;
              padding: 30px 40px;
              .input-search-row-wrapper {
                background-color: $white-bg-1;
                padding: 20px;
                justify-content: space-between;
                .search-input-wrapper {
                  .ant-form-item-row {
                    flex-direction: column;
                    .ant-form-item-label {
                      text-align: left;
                      label {
                        height: auto;
                        &:after {
                          content: none;
                        }
                      }
                      .form-label-text {
                        margin-bottom: 5px;
                        font-weight: 500;
                        font-size: 14px;
                        display: block;
                      }
                    }
                  }
                  .form-dropdown {
                    height: 35px;
                    .ant-select-selector {
                      height: 35px;
                      padding: 4px 12px;
                      background-color: $white-bg-1;
                      border: 1px solid $input-border;
                      border-radius: 4px;
                      .ant-select-selection-item {
                        display: flex;
                        align-items: center;
                      }
                    }
                  }
                  .search-input {
                    height: 35px;
                    padding: 4px 12px;
                    background-color: $white-bg-1;
                    border: 1px solid $input-border;
                    border-radius: 4px;
                  }
                }
              }
              .advance-tab-wrapper {
                .ant-collapse-header {
                  background-color: $advance-filter-bg-color;
                  .ant-collapse-expand-icon {
                    svg {
                      right: 10px;
                      top: 50%;
                      width: 15px;
                      height: 15px;
                    }
                  }
                  .ant-collapse-header-text {
                    font-size: 14px;
                    font-weight: 500;
                    padding: 10px 25px;
                  }
                }
                .ant-collapse-content {
                  background-color: $advance-filter-bg-color;
                  .ant-collapse-content-box {
                    padding: 15px 17px;
                    .advance-criteria-wrapper {
                      background-color: $white-bg-1;
                      padding: 20px 25px;
                      .advance-criteria-title-wrapper {
                        margin-bottom: 10px;
                        // .advance-criteria-title {
                        //   font-size: 18px;
                        // }
                      }
                      .ant-col-5 {
                        display: flex;
                        align-items: center;
                        .advance-tab-title-wrapper {
                          display: flex;
                          flex-direction: column;
                          // height: 220px;
                          width: calc(100% - 44px);
                          overflow-x: visible;
                          span {
                            font-size: 16px;
                            font-weight: 500;
                            width: 100%;
                            display: block;
                            text-align: right;
                            color: $not-active-filter-tab-color;
                            cursor: pointer;
                            span {
                              display: inline-block;
                              width: auto;
                            }
                            &:not(:last-child) {
                              margin-bottom: 23px;
                            }
                          }
                          .active-tab {
                            position: relative;
                            span {
                              color: $black-color;
                              border-bottom: 2px solid $advance-active-tab-color;
                            }
                            &:before {
                              content: '';
                              position: absolute;
                              top: 0px;
                              right: -45px;
                              width: 0px;
                              height: 0px;
                              border: 15px solid transparent;
                              border-right: 20px solid $table-border;
                            }
                          }
                        }
                      }
                      .advance-tab-filter-content-wrapper {
                        min-height: 100px;
                        background: $table-border;
                        .kpi-content-wrapper {
                          height: 100%;
                          .ant-form-item {
                            display: inline-flex;
                            margin: 0;
                          }
                          .radio-btn-wrapper {
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-left: 10px;
                            .kpi-selection-wrapper {
                              .ant-form-item-has-error {
                                .ant-radio-group {
                                  .ant-radio-button-wrapper {
                                    border-color: $input-validation-border-color;
                                  }
                                }
                              }
                              .percentage-field-wrapper {
                                display: inline-flex;
                                align-items: center;
                                span {
                                  font-size: 16px;
                                  font-weight: 600;
                                }
                                .percentage-sign {
                                  margin-left: 5px;
                                }
                              }
                              .trend-amt-wrapper {
                                .ant-input-number-group-wrapper {
                                  width: 130px;
                                  margin-left: 10px;
                                  .ant-input-number-wrapper {
                                    .ant-input-number {
                                      width: 75px;
                                      border-radius: 0px;
                                      margin-left: 0px;
                                    }
                                  }
                                  .ant-input-number-group-addon {
                                    .ant-select-single {
                                      .ant-select-selector {
                                        .ant-select-selection-item {
                                          font-size: 14px;
                                          font-weight: 400;
                                        }
                                      }
                                      .ant-select-arrow {
                                        color: rgba($black-color, 0.5);
                                        .anticon-down,
                                        .ant-select-suffix {
                                          font-size: 12px;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              .title {
                                margin-bottom: 15px;
                                display: block;
                                font-size: 14px;
                                font-weight: 500;
                              }
                              .ant-radio-group {
                                width: auto;
                                .ant-radio-button-wrapper {
                                  width: 50%;
                                  border-color: #c1bdbd;
                                  span:last-child {
                                    display: inline-flex;
                                    align-items: center;
                                    span:first-child {
                                      white-space: pre;
                                    }
                                  }
                                  .ant-radio-button {
                                    border-color: #c1bdbd;
                                  }
                                  &:first-child {
                                    border-radius: 4px 0 0 4px;
                                  }
                                  &:last-child {
                                    border-radius: 0 4px 4px 0;
                                  }
                                  .radio-value {
                                    font-weight: 700;
                                  }
                                  .anticon {
                                    font-weight: 700;
                                    margin-left: 5px;
                                  }
                                }
                                .ant-radio-button-wrapper-checked {
                                  border-color: #c1bdbd;
                                  &:before {
                                    background-color: #c1bdbd;
                                  }
                                  .ant-radio-button {
                                    background-color: $table-border;
                                    border-color: #c1bdbd;
                                    &:first-child {
                                      border-radius: 4px 0 0 4px;
                                    }
                                    &:last-child {
                                      border-radius: 0 4px 4px 0;
                                    }
                                  }
                                }
                              }
                              .ant-input-number {
                                margin-left: 10px;
                                width: 60px;
                                border-radius: 4px;
                                .ant-input-number-input-wrap {
                                  .ant-input-number-input {
                                    text-align: center;
                                    padding: 0 11px 0 0 !important;
                                  }
                                }
                              }
                            }
                          }
                          .year-selection-wrapper {
                            height: 100%;
                            display: flex;
                            align-items: center;
                            .year-range-wrapper {
                              .title {
                                margin-bottom: 15px;
                                display: block;
                                font-size: 14px;
                                font-weight: 500;
                              }
                              .datepicker-wrapper {
                                .date-picker {
                                  border-radius: 4px;
                                  width: 100px;
                                }
                                .range-divider {
                                  font-size: 20px;
                                  font-weight: 600;
                                  margin: 0 10px;
                                }
                              }
                            }
                          }
                        }
                      }
                      .ant-divider.tab-divider {
                        height: 100%;
                        border-left: 3px solid $card-header-bg;
                      }
                      .ant-checkbox-group {
                        height: 255px;
                        overflow-x: auto;
                        .ant-checkbox-wrapper {
                          @include checkboxCommon;
                        }
                      }
                    }
                    .plus-icon-wrapper {
                      text-align: center;
                      position: relative;
                      height: 15px;
                      span {
                        font-size: 35px;
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        left: 50%;
                        color: $card-header-bg;
                      }
                    }
                  }
                }
              }
            }
            .criteria-footer-wrapper {
              margin: 30px 10px 10px;
              text-align: center;
              .form-buttons {
                margin-top: 20px;
                @include filterFormButtons;
              }
            }
          }
        }
      }
    }
    .advance-tab-wrapper {
      .ant-collapse-item {
        .ant-collapse-header {
          padding: 0;
          width: 130px;
        }
      }
    }
  }
  .filter-tabs {
    min-height: 300px;
    margin-bottom: 20px;
    .ant-tabs-nav {
      margin: 0;
      .ant-tabs-nav-wrap {
        overflow: visible;
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            border: 2px solid $primary-background-color;
            border-bottom: 0;
            border-radius: 8px 8px 0px 0px;
            background-color: $app-bg-color;
            color: $font-color !important;
          }
          .ant-tabs-tab-active {
            border-bottom: none;
            background-color: $white-bg-1;
            .ant-tabs-tab-btn {
              color: $font-color;
              text-shadow: none;
            }
            &:after {
              content: '';
              background-color: $white-bg-1;
              display: block;
              height: 4px;
              position: absolute;
              bottom: -2px;
              width: 100%;
              z-index: 10;
              left: 0;
              right: 0;
            }
          }
        }
      }
    }
    .ant-tabs-content-holder {
      background-color: $white-bg-1;
      border: 2px solid $primary-background-color;
      border-radius: 0px 8px 8px 8px;
      padding: 16px 32px;
    }
  }
}
/* END */

/* Department Filter List Design Start */

.department-list-wrapper {
  display: flex;

  .department-radio-wrapper {
    .dept-radio-group {
      margin: 10px 0 0 15px;
      .ant-radio-group {
        display: flex;
        flex-direction: column;
        .radio-value {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .department-content-wrapper {
    display: flex;
    flex-direction: column;
    .dept-checkbox-group {
      height: 260px;
      overflow-x: auto;
      .ant-checkbox-group {
        width: 100%;
      }
    }
    .selected-department {
      background-color: $background-color;
      .ant-checkbox-indeterminate .ant-checkbox-inner:after {
        @include indeterminateCheckbox;
      }
    }
    .sider-checkbox-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 10px;
      cursor: pointer;
      div {
        display: flex;
        align-items: center;
      }
      span {
        font-size: 14px;
        font-weight: 500;
      }
      .arrow-icon {
        width: 30px;
        border-radius: 100%;
        text-align: center;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          font-size: 12px;
        }
      }
      .active {
        background: $active-dept-arrow-color;
        color: $white-bg-1;
      }
    }
  }

  .department-section-content-wrapper {
    .section-checkbox-group {
      height: 260px;
      overflow-x: auto;
    }
    .ant-checkbox-group {
      width: 100%;
      .sider-checklist-value {
        width: 100%;
      }
    }
  }

  .department-content-wrapper:not(:last-child) {
    border-right: 2px solid $background-color;
  }

  .ant-checkbox-wrapper-checked {
    padding: 10px;
    background-color: $background-color;
  }

  .ant-checkbox-wrapper {
    padding: 10px;
    margin: 0px !important;
  }

  .ant-typography {
    text-align: center;
  }

  label {
    font-size: 16px;
  }
}

/* END */

/* Aggregation Component Design Start */
.aggregation-card-wrapper {
  .aggregation-accordion-wrapper {
    margin-bottom: 20px;
    .ant-collapse-header {
      padding: 12px 16px !important;
      background: rgba($active-navigation-tab-color, 0.15);
      .ant-collapse-header-text {
        font-size: 20px;
        font-weight: 500;
      }
      .ant-collapse-expand-icon {
        > svg {
          right: 20px;
          transition: all 0.2s ease-in-out;
        }
        > .down-arrow-rotate {
          transition: all 0.2s ease-in-out;
          transform: translateY(-50%) rotate(180deg) !important;
        }
      }
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        background: rgba($active-navigation-tab-color, 0.15);
      }
    }
    .amount-card-wrapper {
      position: relative;
      background-color: $white-bg-1;
      .blank-policy-col {
        height: 100px;
        margin: 0;
        background-color: $primary-background-color;
        border-radius: 8px 0 0 0;
      }
      .blank-text-col {
        height: 80px;
        margin: 10px 0 0 10px;
        background-color: $table-border;
      }
      .full-width {
        width: calc(100% - 460px);
      }
      .aggregation-radio-tab {
        text-align: right;
        .ant-radio-group {
          margin: 0 0 40px;
          .ant-radio-button-wrapper {
            border: 1px solid $primary-background-color;
            background-color: $app-bg-color;
            color: $primary-background-color;
            font-size: 16px;
            height: 40px;
            padding: 5px 15px;
            line-height: normal;
            &:first-child {
              border-radius: 4px 0px 0px 4px;
            }
            &:last-child {
              border-radius: 0px 4px 4px 0px;
            }
          }
          .ant-radio-button-wrapper-checked {
            background-color: $primary-background-color;
            color: $white-bg-1;
            &:before {
              content: none;
            }
          }
        }
      }
      // .aggregation-tabs {
      .aggregation-amt-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-divider-vertical {
          height: 75px;
          border-left: 1px solid $card-header-bg;
        }
        .ant-col {
          text-align: center;
          .title {
            font-size: 16px;
            color: $summary-text-color;
            font-weight: 500;
            sub {
              bottom: 0;
              font-size: 12px;
              color: $summary-text-color;
            }
          }
          .amount {
            display: flex;
            flex-direction: column;
            .actual {
              font-size: 28px;
              font-weight: 700;
              sub {
                bottom: 0;
                font-size: 16px;
                font-weight: 500;
              }
            }
            .difference {
              color: #5195f1;
            }
            .down-amount {
              color: #f37935;
            }
          }
        }
      }
      .ant-divider-horizontal {
        border-top: 1px solid $card-header-bg;
        min-width: calc(100% - 24px);
        width: calc(100% - 24px);
        margin: 24px;
      }
      .project-amt-wrapper {
        margin-bottom: 30px;
        .ant-divider-vertical {
          height: 75px;
          border-left: 1px solid $card-header-bg;
        }
        .ant-col {
          text-align: center;
          display: flex;
          flex-direction: column;
          .title {
            font-size: 16px;
            color: $summary-text-color;
            font-weight: 700;
          }
          .amount {
            font-size: 28px;
            font-weight: 700;
            sub {
              bottom: 0;
              font-size: 16px;
              font-weight: 500;
            }
          }
        }
      }
      // }

      .aggregation-tab-b-wrapper {
        .title-wrapper {
          margin: 0;
          background-color: $primary-background-color;
          color: $white-bg-1;
          font-size: 18px;
          border-radius: 0px 0px 0px 8px;
          text-orientation: upright;
          writing-mode: vertical-lr;
          display: flex;
          align-items: center;
        }
        .content-wrapper {
          margin: 0 0 10px 10px;
          background-color: $table-border;
          padding: 0 50px;
          & > div {
            display: flex;
            position: absolute;
            flex-direction: column;
            top: -50px;
            .title {
              font-size: 24px;
              font-weight: 500;
              margin-bottom: 30px;
            }
            .content {
              font-size: 20px;
              font-weight: 500;
            }
          }
          span {
            font-size: 18px;
          }
        }
        .amount-wrapper {
          margin-bottom: 30px;
          padding: 0 50px;
          .title {
            font-size: 18px;
          }
          .amount {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .actual {
              font-size: 24px;
              font-weight: 700;
            }
            .percentage {
              color: $primary-background-color;
            }
          }
          .difference {
            color: $primary-background-color;
          }
        }
        .amount-divider {
          .ant-divider-vertical {
            height: 100px;
            border-left: 3px solid $card-header-bg;
          }
        }
        .financial-diff-wrapper {
          .gen-spec-fin-wrapper {
            display: flex;
            justify-content: space-between;
            .ant-divider-vertical {
              border-left: 1px solid $card-header-bg;
              height: 70px;
            }
            .general-fin-wrapper,
            .specific-fin-wrapper {
              display: flex;
              flex-direction: column;
              .title {
                font-size: 18px;
              }
              .amount {
                display: flex;
                align-items: center;
                .actual {
                  font-size: 20px;
                  font-weight: 500;
                }
                .difference {
                  color: $primary-background-color;
                }
              }
              .percentage {
                color: $primary-background-color;
              }
            }
            .close-icon-wrapper {
              display: flex;
              margin: 20px 100px 10px;
              .close-accordion-content {
                margin: 0;
                width: fit-content;
                cursor: pointer;
                .close-text {
                  color: $primary-background-color;
                  margin-left: 10px;
                  font-size: 16px;
                  font-weight: 700;
                }
              }
            }
          }
        }
      }

      .aggregation-tab-c-wrapper {
        margin-bottom: 20px;
        .ant-col {
          display: flex;
          text-align: center;
          flex-direction: column;
          .title {
            font-size: 16px;
            font-weight: 500;
            color: $summary-text-color;
          }
          .amount {
            font-size: 28px;
            font-weight: 700;
            sub {
              bottom: 0;
              font-size: 16px;
              font-weight: 500;
            }
          }
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            fill: #bdbdbd;
          }
        }
      }
    }
  }
}
/* END */

/* Table Column Setting Model Design Start */
.table-setting-modal-wrapper {
  .ant-modal-content {
    @include commonModal;
    .ant-modal-body {
      .column-setting-wrapper {
        .list-item-wrapper {
          margin: 0 25px;
          .list-item-title {
            font-size: 19px;
            font-weight: 400;
            margin: 30px 0 20px;
          }
          .column-drag-wrapper {
            margin-left: 10px;
            min-height: 20px;
            .ant-row {
              display: flex;
              align-items: center;
              margin-bottom: 25px;
              .svg-icon {
                cursor: pointer;
                display: flex;
              }
              .text-title {
                font-size: 20px;
              }
            }
          }
        }
        .column-divider {
          width: calc(100% - 50px);
          border: 1px solid $font-color;
        }
      }
    }
  }
}
/* END */

/* PDF Viewer Design Start */
.pdf-viewer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  .direction-icon {
    width: 84px;
    height: 84px;
    background-color: $direction-icon-bg;
    border-radius: 50%;
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
    justify-content: center;
  }
  .disabled-arrow {
    opacity: 50%;
    cursor: not-allowed;
  }
  .pdf-content-wrapper {
    display: flex;
    .react-pdf__Page {
      .react-pdf__Page__textContent {
        top: calc(100% - 440px) !important;
      }
    }
  }
}
/* END */

/* Project Administrator Design Start */
.project-adminstrator-wrapper {
  .fixed-widgets {
    position: fixed;
    right: 0px;
    bottom: 0px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .issue-icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 159px;
      height: 43px;
      background-color: #0d53bb;
      box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
      border-radius: 6px 0px 0px 0px;
      span {
        font-size: 16px;
        margin-left: 5px;
        font-weight: 700;
        color: $white-bg-1;
      }
    }
  }
  .administrator-card-wrapper {
    .administrator-tabs {
      > .ant-tabs-nav {
        margin: 0;
        z-index: 1;
        @include commonAdministratorTabs;
      }
      > .ant-tabs-content-holder {
        border: none;
        .pj-filter-accordion-wrapper {
          margin: 0;
        }
        .group-project-wrapper {
          // margin-top: -50px;
          .pj-filter-accordion-wrapper {
            margin-top: 30px;
          }
        }
        .heading-row-management {
          margin-top: 10px;
          .model {
            text-align-last: right;

            .model-btn {
              margin-left: 3px;
              @include borderedButton;
            }
          }
        }
        .group-list-card-wrapper {
          > .ant-tabs {
            .ant-tabs-nav {
              .ant-tabs-nav-wrap {
                .ant-tabs-nav-list {
                  .ant-tabs-tab {
                    border: 1px solid $card-header-bg;
                    background-color: $white-bg-1;
                    font-size: 14px;
                    font-weight: 500;
                    margin: 0;
                    color: $black-color;
                  }
                  .ant-tabs-tab-active {
                    background: $app-bg-color;
                    .ant-tabs-tab-btn {
                      color: $black-color;
                    }
                  }
                }
              }
            }
          }
          .group-list-table-wrapper {
            background-color: $white-bg-1;
            border: 0;
            border-radius: 20px;
            padding: 25px 32px;
            .search-result-heading-wrapper {
              display: flex;
              align-items: center;
              .search-result-title {
                font-size: 22px;
                font-weight: 700;
                padding-left: 18px;
                border-left: 3px solid $primary-background-color;
              }
            }
            .table-content-wrapper {
              margin-top: 30px;
            }
            .model {
              text-align-last: right;
              .model-btn {
                @include borderedButton;
                &:first-child {
                  margin-right: 10px;
                }
              }
            }
          }
        }

        .wrapper {
          .ant-table-wrapper {
            overflow-x: auto;

            .ant-table-cell-fix-left,
            .ant-table-cell-fix-right {
              z-index: 0;
            }

            .ant-table-ping-right {
              .ant-table-cell-fix-right-first::after {
                box-shadow: inset -12px 0 8px -8px $box-shadow-color;
              }
            }
            .ant-table-container {
              table {
                @include commonTable;
                tbody {
                  tr {
                    td {
                      font-weight: 500;
                      .group-category {
                        border: 1px solid $black-color;
                        border-radius: 20px;
                        height: 32px;
                        width: 130px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 500;
                        padding: 5px 10px;
                      }
                      .deadline-date {
                        display: flex;
                        align-items: center;
                        svg {
                          margin-left: 10px;
                        }
                      }
                      .is-deadline {
                        color: $emergency-btn-bg;
                      }
                      .no-limit-amount {
                        color: $emergency-btn-bg;
                      }
                      .group-table-action-wrapper {
                        display: flex;
                        align-items: center;
                      }
                    }
                  }
                }
                .project-manage-open-btn {
                  width: 100px;
                  @include commonButton;
                }
                .start-workflow-column-btn {
                  width: auto;
                  @include borderedButton;
                }

                .more-btn {
                  padding-left: 10px;
                  font-size: 22px;
                }
              }
            }
          }
        }
      }
    }
  }
}
/* END */

/* Issue List Modal Design Start */

.issue-manage-tooltip {
  padding-right: 5px !important;
  .ant-tooltip-content {
    .ant-tooltip-arrow {
      display: none;
    }
    .ant-tooltip-inner {
      background: $white-bg-1;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
      border-radius: 0 !important;
      .issue-manage-btn-wrapper {
        display: flex;
        flex-direction: column;
        .ant-btn {
          @include commonButton;
          min-width: 100px;
          &:last-child {
            margin-top: 5px;
            background-color: $black-color;
          }
        }
      }
    }
  }
}

.issue-list-modal-wrapper {
  right: 5px;
  left: unset;
  position: fixed !important;
  bottom: 45px;
  top: unset !important;
  max-width: 510px !important;
  padding: 0 !important;
  background: $white-bg-1;
  box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.25);
  .ant-modal-content {
    .ant-modal-close {
      span.ant-modal-close-x {
        display: flex;
        justify-content: center;
        align-items: center;
        .anticon-close-circle {
          font-size: 25px;
          background: $white-bg-1;
          border-radius: 100%;
          margin-bottom: 3px;
        }
        svg {
          fill: $white-bg-1;
          path:first-child {
            fill: $font-color;
          }
        }
      }
    }
  }
  .ant-modal-header {
    padding: 10px 15px;
    background: $table-border;
    .issue-list-modal-header {
      display: flex;
      align-items: center;
      .title {
        font-size: 20px;
        font-weight: 500;
      }
      .new-issue-btn {
        @include commonButton;
        font-weight: 600;
        margin-right: 40px;
      }
      .dropdown {
        margin-top: 11px;
        .issue-dropdown {
          width: 100%;
          .ant-select-selector {
            border-radius: 4px;
            border: 1px solid $input-border;
          }
        }
      }
    }
  }
  .ant-modal-body {
    padding: 0px;
    position: relative;
    .issue-list-card-wrapper {
      max-height: calc(100vh - 200px);
      min-height: 100px;
      overflow: auto;
      padding: 22px;
      position: relative;
      .current-user-avatar {
        margin-right: 10px;
        font-size: 16px;
        color: $white-bg-1;
        border-radius: 100%;
        padding: 5px 10px;
        text-transform: capitalize;
        font-weight: 700;
        background-color: $primary-background-color;
      }
      .ant-card {
        background: $table-border;
        border-radius: 8px;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        .ant-card-head {
          border-radius: 8px 8px 0 0;
          background: $table-border;
          .ant-card-head-title {
            white-space: unset !important;
            .ant-row {
              display: flex;
              align-items: center;
              .title {
                font-size: 16px;
                font-weight: 700;
                text-transform: capitalize;
              }
              .emergency-button {
                height: 100%;
                border: 2px solid $emergency-btn-bg;
                background-color: $white-bg-1;
                border-radius: 4px;
                color: $emergency-btn-bg;
                font-size: 16px;
                font-weight: 700;
                padding: 2px 10px;
                margin-right: 10px;
              }
              .issue-manage-icon {
                font-size: 18px;
                background: $white-bg-1;
                padding: 4px;
                border-radius: 50%;
                cursor: pointer;
              }
            }
          }
        }
        .ant-card-body {
          padding: 0 6px 6px;
          .issue-content-wrapper {
            background: $white-bg-1;
            padding: 15px;
            display: flex;
            align-items: center;
            .text-content {
              font-size: 16px;
              font-weight: 400;
              margin-bottom: 15px;
              .remaining-day-count {
                color: $emergency-btn-bg;
                font-weight: 700;
              }
            }
            .issue-form-dropdown {
              margin-left: 10px;
              width: 50%;
              .ant-select-selector {
                border-radius: 4px;
              }
            }
            .comment-icon {
              display: flex;
              justify-content: flex-end;
              margin-top: -25px;
              .comment-count {
                cursor: pointer;
                right: -5px;
                background: $card-header-bg;
                color: $font-color;
                height: 25px;
                width: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100px;
                font-size: 14px;
                font-weight: 500;
                position: relative;
                padding: 0px 9px 1px 9px;
                &:before {
                  content: '';
                  position: absolute;
                  width: 0px;
                  height: 0px;
                  border: 6px solid transparent;
                  border-bottom: 10px solid #d9d9d9;
                  transform: rotate(139deg);
                  top: 17px;
                  right: -4px;
                }
              }
            }
          }
          .comment-wrapper {
            background: $white-bg-1;
            padding: 15px;
            .all-comment-show {
              margin-bottom: 15px;
            }
            .comment-box-wrapper {
              max-height: 300px;
              background: $white-bg-1;
              overflow: auto;
              .comment-content-wrapper {
                .ant-row {
                  &:not(:first-child) {
                    margin: 10px;
                  }
                }
                .user-info-wrapper {
                  margin-top: 10px;
                  display: flex;
                  align-items: center;
                  .icon {
                    svg {
                      height: 50px;
                      width: 50px;
                    }
                  }
                  .user-data {
                    display: flex;
                    flex-direction: column;
                    span {
                      &:first-child {
                        font-size: 16px;
                        font-weight: 400;
                      }
                      &:last-child {
                        font-size: 12px;
                        font-weight: 400;
                        color: $menu-item-color;
                      }
                    }
                  }
                  .more-icon {
                    cursor: pointer;
                    svg {
                      font-size: 20px;
                      fill: $gray-color;
                    }
                  }
                }
                .comment-text-wrapper {
                  font-size: 14px;
                  font-weight: 400;
                  color: $menu-item-color;
                  .edit-comment-input {
                    .ant-input-suffix {
                      @include inputSuffixCommon;
                      span {
                        margin-left: 5px;
                        &:first-child {
                          padding-right: 5px;
                          border-right: 1px solid #bac4c5;
                        }
                      }
                      .comment-btn {
                        padding: 0;
                      }
                      .disabled {
                        cursor: not-allowed;
                        svg {
                          fill: $input-border;
                        }
                      }
                    }
                  }
                }
                .comment-count-wrapper {
                  font-size: 11px;
                  color: rgba($menu-item-color, 0.7);
                  display: block;
                  margin: -10px 0 0 0;
                  cursor: pointer;
                }
              }
              .comment-seperator {
                border-color: $table-border;
                border-width: 2px;
                margin: 15px 0;
                &:first-child {
                  margin-top: 0;
                }
              }
            }
            .comment-input-wrapper {
              margin-top: 0;
              display: flex;
              align-items: center;
              .comment-input-field {
                height: 48px;
                border-radius: 4px;
                border: 1px solid $input-border;
                padding: 0px;
                padding-left: 11px;
                .ant-input-suffix {
                  @include inputSuffixCommon;
                  .disabled {
                    cursor: not-allowed;
                    svg {
                      fill: $input-border;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .no-issue-text {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        font-size: 20px;
      }
    }
  }
}
/* END */

/* Create Issue Model Design Start */
.create-issue-modal-wrapper {
  .ant-modal-content {
    @include commonModal;
    .ant-modal-body {
      .issue-form-input-wrapper {
        margin: 25px 20px;
        .ant-col {
          .ant-form-item {
            margin-bottom: 8px;
            .ant-form-item-row {
              flex-direction: column;
              .ant-form-item-label {
                text-align: left;
                label {
                  height: auto;
                  &:after,
                  &:before {
                    content: none;
                  }
                }
              }
            }
            .ant-input-status-error {
              border-color: $input-validation-border-color;
            }
          }
        }
        .issue-form-label {
          font-size: 16px;
          display: block;
          margin-bottom: 5px;
        }
        .issue-form-input {
          height: 48px;
          background-color: $white-bg-1;
          border: 1px solid $input-border;
          border-radius: 4px;
          padding: 12px;
          font-size: 16px;
        }
        .rc-input-disabled {
          cursor: not-allowed;
          background-color: rgba(239, 239, 239, 0.3) !important;
          color: rgb(84, 84, 84) !important;
          border-color: rgba(118, 118, 118, 0.3) !important;
        }
        .issue-form-area {
          background-color: $white-bg-1;
          border: 1px solid $input-border;
          border-radius: 4px;
          padding: 12px;
          font-size: 16px;
        }
        .issue-form-dropdown {
          .ant-select-selector {
            height: 48px;
            padding: 12px;
            background-color: $white-bg-1;
            border: 1px solid $input-border;
            border-radius: 4px;
            font-size: 16px;
            .ant-select-selection-item {
              display: flex;
              align-items: center;
            }
          }
          height: 48px;
        }
      }
    }
  }
}
/* END */

/*line-chart Design start*/
.line-chart-summary {
  .header-summary {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    .header-btn {
      text-align-last: right;
      .btn {
        color: $primary-background-color;
        border-color: $primary-background-color;
        border-radius: 5px;
      }
    }
  }
  .dropdown-summary {
    .dropdown {
      .ant-select-selector {
        border-color: $primary-background-color;
        border-radius: 5px;
      }
      width: 206px;
      margin-left: 10px;
    }
  }
  .summary-wrapper {
    justify-content: center;
    .graph-wrapper {
      margin: 8px 5px 0;
      cursor: pointer;
      .highcharts-container {
        .bottom-label {
          span {
            margin-top: 15px !important;
            text-align: right;
          }
        }
        .highcharts-popup {
          display: none !important;
        }
        .highcharts-annotation-label {
          left: 0 !important;
          span {
            word-break: break-word !important;
            white-space: normal !important;
            width: 280px;
          }
        }
        svg {
          width: 300px !important;
          height: 150px !important;
          margin-top: 32px !important;
          .highcharts-exporting-group {
            transform: translate(48px, -9px);
          }
        }
        .highcharts-contextmenu {
          .highcharts-menu {
            max-height: 160px;
            overflow: auto;
          }
        }
      }
    }
  }
}
/*END*/

/* Summary Screen Design Start */
.card-summary,
.per-summary {
  .graph-column-wrapper {
    // Add below css for align items

    display: flex;
    align-items: center;
  }
  .graph-row {
    cursor: pointer;
  }
  .header-summary {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    .header-btn {
      text-align-last: right;
      .btn {
        color: $primary-background-color;
        border-color: $primary-background-color;
        border-radius: 5px;
      }
    }
  }
  .dropdown-summary {
    .dropdown {
      .ant-select-selector {
        border-color: $primary-background-color;
        border-radius: 5px;
      }
      width: 106px;
      margin-left: 10px;
    }
  }
  .summary-wrapper {
    justify-content: center;
    margin: 1px;
    .graph-wrapper {
      margin: 5px 2px 0;
      .highcharts-container {
        width: 100%;
        .bottom-label {
          span {
            margin-top: 15px !important;
            text-align: right;
          }
        }
        .highcharts-annotation {
          width: 100%;
          .highcharts-annotation-labels {
            width: 100%;
            .highcharts-annotation-label {
              width: 100%;
              left: 0 !important;
              span {
                word-break: break-word !important;
                white-space: normal !important;
                width: calc(100% - 15px);
              }
            }
          }
        }
        svg {
          width: 100% !important;
          height: 120px !important;
          margin-top: 40px !important;
        }
      }
    }
  }
  .per-card-main {
    .header-summary {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 600;
      .header-btn {
        text-align-last: right;
        .btn {
          color: $primary-background-color;
          border-color: $primary-background-color;
          border-radius: 5px;
        }
      }
    }
    padding-left: 10px;
    .percentage-card {
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      .ant-col {
        width: 270px;
        height: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 5px 2px 0;
        position: relative;
        background-color: #f5f9fa;
        .main-text {
          color: #61a2fa96;
        }

        .left-text {
          font-size: 16px;
          font-weight: 700;
          margin-left: 4px;
          display: block;
          width: auto;
          position: absolute;
          top: 0px;
          left: 0px;
          padding: 10px 10px 0;
        }
        .main-text {
          font-size: 42px;
          font-weight: 700;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          height: 100%;
          .per {
            color: black;
            font-size: 25px;
          }
        }
      }
    }
  }
  .funnel-chart-wrapper {
    .highcharts-container {
      transform: rotate(90deg);
      .highcharts-tooltip {
        transform: translate(50%, 50%) rotate(-90deg);
      }
    }
  }
  .add-icon {
    border: 1px dashed $primary-background-color;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 90px 0px 0px 30px;
    svg {
      margin-top: 20px;
      font-size: 14em;
      color: $primary-background-color;
    }
  }
}
.group-column-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      background-color: white;
      color: black;
      border: 2px solid $primary-background-color;
    }
    .ant-tooltip-arrow {
      visibility: hidden;
    }
  }
}
/* END */

/* Percentage graph Design start*/
.per-summary {
  .header-summary {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    .header-btn {
      .btn {
        color: $primary-background-color;
        border-color: $primary-background-color;
        border-radius: 5px;
      }
    }
  }
  .dropdown-summary {
    .dropdown {
      .ant-select-selector {
        border-color: $primary-background-color;
        border-radius: 5px;
      }
      width: 106px;
      margin-left: 10px;
    }
  }
}
/*END*/

/* Project details design start */
.filter-card-wrapper-details {
  .filter-tabs {
    min-height: 300px;
    margin-bottom: 20px;
    .ant-tabs-nav {
      margin: 0;
      .ant-tabs-nav-wrap {
        overflow: visible;
        justify-content: flex-end;
        right: 20px;
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            border: 2px solid $tab-color;
            border-bottom: 0;
            border-radius: 8px 8px 0px 0px;
            background-color: $tab-color;
            color: $font-color !important;
            margin-left: 10px;
            .ant-tabs-tab-btn {
              color: $white-bg-1;
            }
          }
          .ant-tabs-tab-active {
            border-bottom: none;
            border: 2px solid $white-bg-1;
            background-color: $white-bg-1;
            .ant-tabs-tab-btn {
              color: black;
              text-shadow: none;
              padding-left: 10px;
              border-left: 3px solid $primary-background-color;
            }
            &:after {
              content: '';
              background-color: $white-bg-1;
              display: block;
              height: 4px;
              position: absolute;
              bottom: -2px;
              width: 100%;
              z-index: 10;
              left: 0;
              right: 0;
            }
          }
        }
      }
    }
    .ant-tabs-content-holder {
      background-color: $white-bg-1;
      border: 2px solid $white-bg-1;
      border-radius: 10px 10px 10px 10px;
      padding: 16px 32px;
      .detail-tab {
        .chart-center {
          margin: auto;
          .ant-table-wrapper {
            .ant-table-container {
              .ant-table-content {
                .ant-table-thead {
                  tr {
                    th {
                      font-size: 16px;
                      white-space: nowrap;
                      font-weight: 700;
                      background-color: $white-bg-1;
                      padding: 16px 16px;
                      border: 1px solid rgba($black-color, 0.04);
                      background-color: rgba(#f0f0f0, 0.5);
                    }
                  }
                }
                .ant-table-tbody {
                  tr {
                    td {
                      font-size: 16px;
                      padding: 16px 16px;
                      font-weight: 500;
                      border: 1px solid rgba($black-color, 0.04);
                    }
                    &:nth-child(odd) {
                      td {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4) {
                          background-color: rgba(#f0f0f0, 0.5) !important;
                          font-weight: 600;
                        }
                      }
                    }
                    &:nth-child(even) {
                      td {
                        &:nth-child(1) {
                          background-color: rgba(#f0f0f0, 0.5) !important;
                          font-weight: 600;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .heading-text {
          margin-top: 20px;
          margin-bottom: 25px;
          font-size: 16px;
          font-weight: 600;
        }
        .year-tab {
          margin-top: 25px;

          .ant-tabs {
            .ant-tabs-nav {
              margin: 0;
              .ant-tabs-nav-operations {
                display: none;
              }
              .ant-tabs-nav-wrap {
                justify-content: start;
                right: 0;
                .ant-tabs-nav-list {
                  .ant-tabs-tab {
                    margin: 0 0 0 15px;
                    &:first-child {
                      margin: 0;
                    }
                    width: 110px;
                  }
                  .ant-tabs-tab-active {
                    border: 2px solid rgba($black-color, 0.1);
                    background-color: $white-bg-1;
                    .ant-tabs-tab-btn {
                      color: black;
                      text-shadow: none;
                      padding-left: 10px;
                      border-left: 3px solid $primary-background-color;
                    }
                    &:after {
                      content: '';
                      background-color: $white-bg-1;
                      display: block;
                      height: 4px;
                      position: absolute;
                      bottom: -2px;
                      width: 100%;
                      z-index: 10;
                      left: 0;
                      right: 0;
                    }
                  }
                }
              }
            }
            .ant-tabs-content-holder {
              border: 2px solid rgba($black-color, 0.1);
              border-top: 1px solid rgba($black-color, 0.05);
              border-radius: 0px 0px 8px 8px;
              .year-data-wrapper {
                margin-top: 25px;
                .ant-card-head {
                  background-color: $card-header-bg;
                  margin-top: 16px;
                  padding: 0px 15px 0px;
                }
              }
              .download-btn {
                text-align-last: right;
                .btn {
                  color: $primary-background-color;
                  border-color: $primary-background-color;
                  border-radius: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
  .project-detail-tabs {
    > .ant-tabs-nav {
      margin: 0;
      .ant-tabs-nav-wrap {
        overflow: visible;
        justify-content: flex-end;
        right: 20px;
        .ant-tabs-nav-list {
          overflow: hidden;
          padding: 5px 5px 0 0;
          .ant-tabs-tab {
            background: #ffffff;
            box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.25);
            border: 0;
            border-radius: 8px 8px 0px 0px;
            color: $font-color !important;
            min-width: 165px;
            min-height: 50px;
            .ant-tabs-tab-btn {
              color: #292929;
              font-weight: 700;
              width: 100%;
              text-align: center;
            }
          }
          .ant-tabs-tab-active {
            border: 0;
            background: $white-bg-1;
            .ant-tabs-tab-btn {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
/*END*/

/* Not Verified Page Design Start */
.not-access-content-wrapper {
  background-color: $app-bg-color;
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;
  .logout-btn {
    width: 120px;
    padding: 0 15px;
    font-size: 16px;
    background-color: $auth-button-color;
    border-color: $auth-button-color;
    color: $white-bg-1;
  }
  .logout-btn:hover {
    background-color: $auth-button-color;
    border-color: $auth-button-color;
    color: $white-bg-1;
  }
}
.not-verified-content-wrapper {
  height: 100vh !important;
}
/*END */

/*Start Project Management*/
.ant-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      border-radius: 5px;
      .ant-row {
        .ant-col {
          .delete-btn {
            border: 1px solid $white-bg-1;
          }
        }
      }
    }
  }
}
/*End*/

/* BeadCrumb Design Start */
.breadcrumb {
  margin: 0 0 15px;
  .ant-breadcrumb {
    font-size: 16px;
    font-weight: 600;
    color: #686666;
    .ant-breadcrumb-link {
      &:last-child {
        color: #292929;
      }
      cursor: pointer;
    }
  }
}
/* END */

/* Cascader Design Start */
.cascader-wrapper {
  height: 260px;
  .test-cascader-demo,
  .ant-select-focused {
    height: 0;
    border: none;
    .ant-select-selector {
      box-shadow: none !important;
      height: 0px;
      padding: 0;
      border: none !important;
    }
  }
}
.cascader-dropdown {
  width: 100%;
  box-shadow: none !important;
  background-color: $table-border !important;
  z-index: 0 !important;
  height: 256px;
  padding: 0 !important;
  // height: 100%;
  div {
    height: 100%;
    .ant-cascader-menus {
      height: 100%;
      .ant-cascader-menu {
        height: 100%;
        max-width: 33.3333%;
        background-color: $table-border;
        padding: 0;
        &:not(:last-child) {
          border-right: 2px solid $background-color;
        }
        .ant-cascader-menu-item-expand,
        .ant-cascader-menu-item {
          height: auto;
          @include commonCascaderCheckbox;
          .ant-cascader-menu-item-content {
            display: flex;
            align-items: center;
          }
          .ant-cascader-menu-item-expand-icon {
            margin-top: 2px;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .anticon-right {
              font-size: 12px;
            }
          }
          .ant-cascader-menu-item-content {
            font-size: 14px;
            font-weight: 500;
            width: calc(100% - 50px);
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            height: auto;
            position: relative;
            top: 1px;
            .ant-empty {
              display: none;
            }
          }
          &[aria-checked='true'] {
            background-color: $background-color;
          }
        }
        .ant-cascader-menu-item-active {
          background-color: transparent;
          .ant-cascader-menu-item-expand-icon {
            background-color: #81a1a3;
            .anticon-right {
              color: $white-bg-1;
            }
          }
        }
      }
    }
  }
}

.department-cascader-dropdown {
  div {
    .ant-cascader-menus {
      .ant-cascader-menu {
        max-width: 33.3333%;
      }
    }
  }
}

.policy-means-cascader-dropdown {
  div {
    .ant-cascader-menus {
      .ant-cascader-menu {
        // max-width: 33.3333%;
        max-width: 50%;
      }
    }
  }
}

.expenditure-cascader-dropdown {
  div {
    .ant-cascader-menus {
      .ant-cascader-menu {
        max-width: 25%;
      }
    }
  }
}
/* END */

/* Cascader Filter Tags Design Start */
.simple-filter-tags-wrapper {
  display: flex;
  align-items: center;
  .fixed-year-filter-dropdown {
    margin-right: 10px;
    .ant-select,
    .year-full {
      width: 100px;
      .ant-select-selector {
        border-radius: 13px !important;
        background: #a65c26 !important;
        color: $white-bg-1 !important;
        .ant-select-selection-item {
          font-weight: 600 !important;
          text-align: left;
        }
      }
      .ant-select-arrow {
        .ant-select-suffix {
          padding: 3px;
          background-color: $table-border;
          border-radius: 50%;
          color: $black-color;
        }
      }
    }
    .year-dashed {
      .ant-select-selector {
        background: repeating-linear-gradient(
          45deg,
          rgba(166, 92, 38, 0.2),
          rgba(166, 92, 38, 0.2) 5px,
          rgba(166, 92, 38, 0.3) 5px,
          rgba(166, 92, 38, 0.3) 10px
        ) !important;
        color: $white-bg-1 !important;
      }
    }
  }
}

.cascader-filter-tags-wrapper {
  margin-top: 10px;

  .cascader-dropdown-wrapper {
    &:not(:first-child) {
      margin-top: 10px;
    }
    text-align: left;
    .ant-select-selector {
      background: rgb(214, 238, 236) !important;
      border-radius: 4px !important;
      padding: 5px;
      .ant-select-selection-overflow-item {
        .ant-select-selection-item {
          border-radius: 50px;
          border: 1px solid #26a69a;
          background: #26a69a;
          height: auto;
          padding: 5px 10px;
          display: flex;
          align-items: center;
          .ant-select-selection-item-content {
            color: $white-bg-1;
            font-weight: 600;
          }
          .ant-select-selection-item-remove {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            margin-left: 8px;
            background: $table-border;
            border-radius: 50%;
            line-height: normal;
            font-weight: 500;
            .anticon-close {
              color: $black-color;
            }
          }
        }
      }
    }
    .ant-select-clear {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $table-border;
      color: $black-color;
      padding: 5px 10px;
      border-radius: 50%;
      font-size: 14px;
      font-weight: 700;
      opacity: 1;
      width: 14px;
      height: 18px;
      margin-top: -8px;
    }
  }
  .is-pending {
    .ant-select-selector {
      background: repeating-linear-gradient(
        45deg,
        rgba(214, 238, 236, 0.5),
        rgba(214, 238, 236, 0.5) 5px,
        rgba(214, 238, 236, 0.3) 5px,
        rgba(214, 238, 236, 0.2) 10px
      ) !important;
    }
  }
}
/* END */

/* Group Feature Design Start */
.project-lookup-wrapper {
  .datatable-wrapper {
    .group-project-table-wrapper {
      background: $white-bg-1;
      padding: 20px 30px 10px;
      border-radius: 20px;
      border: 3px solid #f8e7dc;
      .group-project-content-wrapper {
        .heading-row {
          .search-result-heading-wrapper {
            .project-count {
              background-color: $primary-background-color;
              color: $white-bg-1;
              font-size: 14px;
              font-weight: 700;
              border-radius: 100%;
              padding: 0 8px;
              display: block;
            }
          }
          .model {
            .model-btn {
              @include borderedButton;
              &:not(:last-child) {
                margin-right: 10px;
              }
            }
            .disabled-btn {
              color: rgba(22, 130, 139, 0.5);
              border-color: rgba(22, 130, 139, 0.5);
            }
          }
        }
      }
    }
    .group-table-wrapper {
      margin-top: 30px;
      background: $white-bg-1;
      padding: 20px 30px 10px;
      border-radius: 20px;
    }
  }
}
/* END */

/* Set Group Limit Model Design Start */
.set-group-limit-modal-wrapper {
  .ant-modal-content {
    @include commonModal;
    .ant-modal-body {
      .issue-form-input-wrapper {
        margin: 25px 20px;
        .ant-col {
          .ant-form-item {
            margin-bottom: 8px;
            .ant-input-status-error {
              border-color: $input-validation-border-color;
            }
          }
        }
      }
      .limit-checkbox-wrapper {
        .ant-checkbox-wrapper {
          display: flex;
        }
      }
    }
  }
}
/* END */

/* Group Limit Aggregation Design Start */
.group-aggregation-wrapper {
  margin-bottom: 20px;
  .aggregation-card-wrapper {
    .aggregation-accordion-wrapper {
      .ant-collapse-header {
        background: #f7dbca;
        .ant-collapse-expand-icon {
          svg {
            path {
              fill: #f37935;
            }
          }
        }
      }
      .ant-collapse-content {
        .ant-collapse-content-box {
          background: #f7dbca;
        }
      }
      .amount-card-wrapper {
        background-color: $white-bg-1;
        .blank-policy-col {
          height: 100px;
          margin: 0;
          background-color: $primary-background-color;
          border-radius: 8px 0 0 0;
        }
        .blank-text-col {
          height: 80px;
          margin: 10px 0 0 10px;
          background-color: $table-border;
        }
        .full-width {
          width: calc(100% - 460px);
        }
        .aggregation-radio-tab {
          .ant-radio-group {
            .ant-radio-button-wrapper {
              border: 1px solid #f37935;
              background-color: $app-bg-color;
              color: #f37935;
            }
            .ant-radio-button-wrapper-checked {
              background-color: #f37935;
              color: $white-bg-1;
            }
          }
        }
      }
    }
  }
  .filter-card-wrapper {
    .group-accordion-wrapper {
      .ant-collapse-item {
        .ant-collapse-content {
          .ant-collapse-content-box {
            .graph-limit-status-wrapper {
              background: $table-border;
              padding: 10px 15px;
              .title {
                font-size: 18px;
                font-weight: 700;
              }
              .amount-content-wrapper {
                padding: 20px 10px;
                .title-wrapper {
                  display: flex;
                  justify-content: space-between;
                  span {
                    color: $menu-item-color;
                    font-size: 16px;
                    font-weight: 500;
                    display: inline-block;
                    width: 50%;
                    text-align: center;
                  }
                }
                .count-wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .count {
                    font-size: 64px;
                    font-weight: 700;
                    display: inline-block;
                    width: 50%;
                    text-align: center;
                  }
                  .limit-with-count {
                    color: #ef9f73;
                  }
                  .divider,
                  .divider-amt {
                    font-size: 38px;
                    font-weight: 700;
                    color: $menu-item-color;
                  }
                  .divider-amt {
                    font-size: 33px;
                  }
                  .limit-amt {
                    font-size: 36px;
                    font-weight: 700;
                    display: inline-block;
                    width: 50%;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
      .graph-limit-summary-wrapper {
        .group-graph-col-wrapper {
          margin-top: -30px;
        }
        .group-graph-wrapper {
          .ant-row {
            display: flex;
            align-items: center;
          }
          .graph-heading-wrapper {
            .title {
              font-size: 20px;
              font-weight: 700;
            }
            .amount-diff {
              font-size: 36px;
              font-weight: 700;
            }
          }
          .limit-request-graph-wrapper {
            position: relative;
            .group-request-graph-wrapper {
              margin-top: -10px;
            }
            .group-limit-graph-wrapper,
            .group-request-graph-wrapper {
              .limit-graph-col {
                &:after {
                  content: '';
                  position: absolute;
                  height: 60px;
                  top: 7px;
                  right: 8px;
                  bottom: 0;
                  left: 0;
                  border-right: 2px dotted #201b1b;
                }
              }
              span {
                font-size: 14px;
                font-weight: 500;
                display: block;
                margin-top: -5px;
              }
              // .highcharts-container {
              //   width: 100% !important;
              //   svg {
              //     width: 100%;
              //   }
              // }
            }
            .difference-content-wrapper {
              display: inline-block;
              position: absolute;
              top: calc(50% - 5px);
              transform: translateY(-50%);
              right: 10%;
              div {
                font-size: 18px;
                color: #ef9f73;
                font-weight: 700;
                sub {
                  bottom: 0;
                }
              }
              .diff-direction {
                font-size: 14px;
              }
            }
          }
          .group-pie-graph-wrapper {
            .ant-col-8 {
              & > div {
                display: flex;
                align-items: center;
                height: 160px;
              }
            }
            .ant-col:not(:last-child) {
              &:after {
                content: '';
                position: absolute;
                height: 90px;
                top: 25%;
                right: 0;
                bottom: 0;
                left: 0;
                border-right: 2px dotted #201b1b;
              }
            }
          }
        }
      }
    }
  }
}
/* END */

/* Start WorkFlow Model Design Start */
.workflow-model-wrapper {
  .ant-modal-content {
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .issue-form-input-wrapper {
    .workflow-date-range-wrapper {
      display: flex;
      .ant-form-item {
        min-width: 200px;
        &:last-child {
          margin: 8px 0 0 10px;
        }
      }
    }
    .multi-user-dropdown {
      .ant-select-selector {
        padding: 0 8px !important;
        .ant-select-selection-overflow {
          .ant-select-selection-overflow-item {
            .ant-select-selection-item {
              border-radius: 100px;
              padding: 10px;
            }
          }
        }
      }
    }
  }
}
/* END */

/* Page UnderConstruction Design Start */
.under-work-svg-wrapper {
  height: calc(100vh - 125px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 600px;
    max-width: 100%;
  }
}
/* END */

/* Column Tooltip Design Start */
// .column-tooltip {
//   opacity: 1;
//   visibility: unset;
// }
.highcharts-tooltip-container {
  // transform: translateX(-100%) translateY(-50px);
  .highcharts-label.highcharts-tooltip.column-tooltip {
    span {
      .workflow-status-tooltip {
        box-shadow: 0px 4px 4px rgba($black-color, 0.25);
        padding: 12px 20px;
      }
    }
  }
}
/* END */

/* TreeMap Label Design Start */
.treemap-label-wrapper {
  > span {
    text-align: center;
  }
  .highcharts-text-outline {
    fill: none;
    stroke: none;
  }
}

.highcharts-breadcrumbs-group {
  font-size: 14px;
}
/* END */
